class NewsScript {

    constructor() {
        let slickExists = $.isFunction($.fn.slick);

        this.setArgs();

        // should only load these where needed, i.e. on the homepage, or custom template, or ...
        if($('div.featured-news__container').length && slickExists) {
            this.featureSlider();
        }

        if($('section.popular-news .thumb-container').length && slickExists) {
            this.popularSlider();
        }

        if($('div.latest-news__container').length && slickExists) {
            this.latestNewsSlider();
        }

        if($('body.news-home div.thumb-container' ).length && slickExists) {
            this.responsiveSlider();
        }

        if($('.related-posts div.thumb-container').length && slickExists) {
            this.responsiveSlider();
        }

        if($('.podcasts div.thumb-container').hasClass('slick-initialized') && slickExists) {
            $('.podcasts div.thumb-container').slick("unslick");
        }


        if($('section.explore').length) {
            this.exploreButtonsWidth = this.getOuterWidth($('.explore__nav-buttons button'));
            this.exploreTerms();
            this.exploreNavCheck();

            $(window).resize(() => {
                this.exploreButtonsWidth = this.getOuterWidth($('.explore__nav-buttons button'));
                this.exploreNavCheck();
            });

        }

        if($('.latest-news__container').length) {
            $(window).resize(function() {
                $('div.latest-news__container').slick('resize');
            });
        }

        if($('.thumb-container').length) {
            $(window).resize(function() {
                $('div.thumb-container').slick('resize');
                $('.podcasts div.thumb-container').slick("unslick");
            });
        }
    }

    setArgs() {
        this.responsiveSliderArgs = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: false,
            cssEase: 'linear',
            touchThreshold: 20,
            rows: 0,
            prevArrow: $(".slick-rel-prev"),
            nextArrow: $(".slick-rel-next"),
            arrows: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1024,
                    settings: "unslick"
                }
            ]
        };

    }

    getArgs() {
        return this.responsiveSliderArgs;
    }


    featureSlider() {
        // custom slide advance
        function nextFeatured($this, nextSlide) {
            // TODO - update so that f-n__container updates when f-n__nav is scrolled
            let $featured_block = $this.closest('.featured-news');

            // advance thumbnails +1 to hide active slide for large screens,

            nextSlide = $(window).width() >= 850 ? nextSlide + 1 : nextSlide;
            $featured_block.find('.featured-news__nav').slick('slickGoTo', (nextSlide));
        }

        // featured news articles main article (larger)
        $(".featured-news__container").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            autoplay: true,
            autoplaySpeed: 20000,
            cssEase: 'linear',
            pauseOnHover: true,
            touchThreshold: 20,
            rows: 0,
            arrows: false,
            rtl: true,
        }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            nextFeatured($(this), nextSlide);
        });

        // featured news side articles
        $('.featured-news__nav')
            .slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                focusOnSelect: false,
                vertical: true,
                verticalSwiping: true,
                initialSlide: 1,
                rows: 0,
                arrows: false,
            })
            .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                nextFeatured($(this), nextSlide);
            });
    }

    //latest news sidebar to slider on small screen
    latestNewsSlider() {
        $("div.latest-news__container").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 20000,
            cssEase: 'linear',
            pauseOnHover: true,
            touchThreshold: 20,
            rows: 0,
            prevArrow: $(".slick-scroll-next "),
            nextArrow: $(".slick-scroll-prev"),
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1024,
                    settings: "unslick"
                }
            ]
        });
    }

    // thumb blocks go to one thumb and slick on small screen sizes
    responsiveSlider() {
        $("div.thumb-container").not('.slick-initialized').slick(this.responsiveSliderArgs);
    }

    popularSlider() {
        $("section.popular-news .thumb-container").slick({
            slidesToShow: 3,
            dots: true,
            appendDots: 'section.popular-news .slide-control__dots',
            adaptiveHeight: false,
            autoplay: false,
            cssEase: 'linear',
            pauseOnHover: true,
            prevArrow: $("section.popular-news .slide-control__prev"),
            nextArrow: $("section.popular-news .slide-control__next"),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });
    }

    exploreTerms = () => {
        let $thumbContainer = $("section.explore .thumb-container");
        let args = this.getArgs();

        /* Explore topics section on Home Page */
        $(".explore__nav > .explore__nav-buttons button").on('click', function() {
            let $this = $(this),
                $slug = $this.data('slug');

            $(".explore__nav button.active").removeClass('active');
            $this.addClass('active');
            $thumbContainer.addClass('loading');

            $('.explore__button a span.topic-label').text($this.text());
            $('.explore__button a').attr('href', $this.data('link'));


            $.ajax({
                url: siteData.ajaxURI,
                method: 'GET',
                dataType: 'json',
                data: {
                    action: 'getTermPostsAJAX',
                    term: $slug,
                    name: "explore"
                },
                success: function(result) {
                    $('section.explore .thumb-container').slick("unslick").html(result['html']).slick(args);
                },
                error: function(result) {
                    console.log("Couldnt do it");
                    console.log(result);
                },
                complete: function() {
                    $thumbContainer.removeClass('loading');
                }
            })
        });


        $(".explore__nav-controls button.nav-overflow").on('click', (i) => {
            this.navOverflowScroll({'direction': i.currentTarget.dataset.dir});
        });

    }


    getOuterWidth(items) {
        let width = 0;

        items.each(function(index, button) {
            width += $(button).outerWidth();
        })

        return width;
    }

    navOverflowScroll(args = []) {

        // console.log(args.direction);
        let containerW = $('.explore__nav').outerWidth();

        let scrollDir = '';

        if(args.direction === 'next') {
            scrollDir = '+=150';
        } else if(args.direction === 'prev') {
            scrollDir = '-=150';
        }

        // if the button container is wider than its parent container
        if(this.exploreButtonsWidth >= containerW) {
            $('.explore__nav').addClass('overlap');

            $('.explore__nav-buttons').animate({scrollLeft: scrollDir}, {duration: 500});
        }
    }

    exploreNavCheck() {
        let containerW = $('.explore__nav').outerWidth();

        if(this.exploreButtonsWidth >= containerW + 1) {
            $('.explore__nav').addClass('nav-controls');
        }

        if(this.exploreButtonsWidth <= containerW + 1 && $('.explore__nav').hasClass('nav-controls')) {
            $('.explore__nav').removeClass('nav-controls');
        }

    }
}

// objectify!
const NewsJS = new NewsScript();

$(document).on("click", "#copyButton", function() {
    $("body").append('<input id="copyURL" type="text" value="" />');
    $("#copyURL").val(window.location.href).select();
    document.execCommand("copy");
    $("#copyURL").remove();
});



